export const Logo = ({ isBrand = true }: { isBrand?: boolean }) => {
  return (
    <div className="cocreation-logo">
      {isBrand
        ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={188}
            height={28}
            viewBox="0 0 188 28"
            fill="none"
            data-testid="brand-svg"
          >
            <path
              d="M57.9108 23.8H71.1548V19.544H62.9508V15.974H70.2169V12.012H62.9508V8.442H71.1548V4.2H57.9108V23.8ZM87.5909 15.358C85.6728 18.41 83.8108 19.684 81.4168 19.684C78.3088 19.684 76.5028 17.5 76.5028 13.706C76.5028 10.108 78.1688 8.316 80.7309 8.316C82.4248 8.316 83.8248 9.072 84.2589 11.592H89.2989C88.7528 6.79 85.6869 3.808 80.7868 3.808C75.1028 3.808 71.3648 7.82599 71.3648 13.986C71.3648 20.188 75.1028 24.192 81.2488 24.192C85.2669 24.192 87.9968 22.512 89.8028 20.244H96.6768V23.8H101.689V4.2H94.5769L87.5909 15.358ZM96.6768 16.31H92.2388L96.6768 9.1V16.31ZM47.1588 4.2H39.7948V23.8H47.1588C52.9969 23.8 56.7628 19.95 56.7628 14C56.7628 8.05 52.9969 4.2 47.1588 4.2ZM47.0888 19.544H44.8348V8.442H47.0888C50.0008 8.442 51.6388 10.5 51.6388 14C51.6388 17.486 50.0008 19.544 47.0888 19.544ZM159.537 3.808C153.615 3.808 149.639 7.826 149.639 14C149.639 20.174 153.615 24.192 159.537 24.192C165.473 24.192 169.435 20.174 169.435 14C169.435 7.82601 165.473 3.808 159.537 3.808ZM159.537 19.684C156.625 19.684 154.791 17.738 154.791 14C154.791 10.262 156.625 8.316 159.537 8.316C162.463 8.316 164.283 10.262 164.283 14C164.283 17.738 162.463 19.684 159.537 19.684ZM102.949 8.442H107.891V23.8H112.931V8.442H117.873V4.2H102.949L102.949 8.442ZM182.301 4.2V14.994L175.805 4.2H170.583V23.8H175.455V12.558L182.217 23.8H187.173V4.2L182.301 4.2ZM142.499 4.2H137.459V23.8H150.101V19.558H142.499V4.2ZM130.963 11.676H124.173V4.2H119.133V23.8H124.173V15.904H130.963V23.8H136.003V4.2H130.963V11.676Z"
              fill="var(--vp-semantic-color-content-brand)"
            />
            <path
              d="M25.5711 0C14.6267 0 1.01309 11.3236 1.01309 20.7085C1.01309 25.5554 4.73612 28 9.65333 28C13.264 28 17.6333 26.6794 21.848 24.1365V5.40893C20.7241 7.33366 15.4416 15.0888 11.1987 19.2193C9.03518 21.3266 7.32118 22.2398 5.84602 22.2398C4.18821 22.2398 3.40146 21.1159 3.40146 19.4441C3.40146 11.8575 16.1722 1.99498 24.6298 1.99498C28.114 1.99498 30.3618 3.54039 30.3618 6.54692C30.3618 9.30055 28.4933 12.7566 25.3041 15.9458V21.7481C30.8676 17.3507 34.1972 11.7451 34.1972 7.22127C34.1972 2.4586 30.4883 0 25.5711 0Z"
              fill="var(--vp-semantic-color-content-brand)"
            />
          </svg>
          )
        : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={188}
            height={28}
            viewBox="0 0 188 28"
            fill="none"
            data-testid="non-brand-svg"
          >
            <path
              d="M58.5109 23.8H71.7549V19.544H63.5509V15.974H70.8169V12.012H63.5509V8.442H71.7549V4.2H58.5109V23.8ZM88.1909 15.358C86.2729 18.41 84.4109 19.684 82.0169 19.684C78.9089 19.684 77.1029 17.5 77.1029 13.706C77.1029 10.108 78.7689 8.316 81.3309 8.316C83.0249 8.316 84.4249 9.072 84.8589 11.592H89.8989C89.3529 6.79 86.2869 3.808 81.3869 3.808C75.7029 3.808 71.9649 7.82599 71.9649 13.986C71.9649 20.188 75.7029 24.192 81.8489 24.192C85.8669 24.192 88.5969 22.512 90.4029 20.244H97.2769V23.8H102.289V4.2H95.1769L88.1909 15.358ZM97.2769 16.31H92.8389L97.2769 9.1V16.31ZM47.7589 4.2H40.3949V23.8H47.7589C53.5969 23.8 57.3629 19.95 57.3629 14C57.3629 8.05 53.5969 4.2 47.7589 4.2ZM47.6889 19.544H45.4349V8.442H47.6889C50.6009 8.442 52.2389 10.5 52.2389 14C52.2389 17.486 50.6009 19.544 47.6889 19.544ZM160.137 3.808C154.215 3.808 150.239 7.826 150.239 14C150.239 20.174 154.215 24.192 160.137 24.192C166.073 24.192 170.035 20.174 170.035 14C170.035 7.82601 166.073 3.808 160.137 3.808ZM160.137 19.684C157.225 19.684 155.391 17.738 155.391 14C155.391 10.262 157.225 8.316 160.137 8.316C163.063 8.316 164.883 10.262 164.883 14C164.883 17.738 163.063 19.684 160.137 19.684ZM103.549 8.442H108.491V23.8H113.531V8.442H118.473V4.2H103.549L103.549 8.442ZM182.901 4.2V14.994L176.405 4.2H171.183V23.8H176.055V12.558L182.817 23.8H187.773V4.2L182.901 4.2ZM143.099 4.2H138.059V23.8H150.701V19.558H143.099V4.2ZM131.563 11.676H124.773V4.2H119.733V23.8H124.773V15.904H131.563V23.8H136.603V4.2H131.563V11.676Z"
              fill="#ffffff"
            />
            <path
              d="M26.1711 0C15.2268 0 1.61313 11.3236 1.61313 20.7085C1.61313 25.5554 5.33616 28 10.2534 28C13.864 28 18.2333 26.6794 22.4481 24.1365V5.40893C21.3241 7.33366 16.0416 15.0888 11.7988 19.2193C9.63521 21.3266 7.92121 22.2398 6.44605 22.2398C4.78824 22.2398 4.0015 21.1159 4.0015 19.4441C4.0015 11.8575 16.7722 1.99498 25.2298 1.99498C28.714 1.99498 30.9619 3.54039 30.9619 6.54692C30.9619 9.30055 29.0933 12.7566 25.9042 15.9458V21.7481C31.4676 17.3507 34.7973 11.7451 34.7973 7.22127C34.7973 2.4586 31.0883 0 26.1711 0Z"
              fill="#ffffff"
            />
          </svg>
          )}
      <span>Co-creation</span>
    </div>
  );
};
